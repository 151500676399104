import React, { useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { date1IsEqualsOrGreaterThanDate2 } from "../../../utils/utils";
import ErrorDisplay from "../errorDisplay/errorDisplay";
import Label from "../label/label";
import "./dateTimeRange.scss";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";

/**translation components */
import { useTranslation } from "react-i18next";

// registerLocale("fr", fr); // register it with the name you want
const DateTimeRange = ({
  name,
  label,
  compact,
  small,
  errors,
  register,
  onChange,
  required,
  validations,
}) => {
  registerLocale("fr", fr);
  registerLocale("en", en);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const onChangevalue = (date, e, isEnDate = false) => {
    if (!isEnDate) setStartDate(date);
    else setEndDate(date);
  };
  const { t, i18n } = useTranslation();
  // Prepare locale. 
  // Since there's no 'en-US' location, replace it with 'en'.
  const locale = i18n.language.replace('-US', ''); 

  return (
    <div className="form-group row datetime-range">
      {!compact && <Label htmlFor={name} label={label} required={required} />}
      <div className="col-12 col-sm-8 d-flex flex-column flex-sm-row">
        <div>
          <div className="date mb-sm-2">
            <ReactDatePicker
              locale={locale}
              selected={startDate}
              className={`form-control ${small ? "form-control-sm" : ""} ${
                errors["startDate"] && !startDate ? "is-invalid" : ""
              } `}
              name="startDatePicker"
              onChange={(date, e) => onChangevalue(date, e)}
              startDate={startDate}
              endDate={endDate}
              dateFormat="MM/dd/yyyy"
              openToDate={new Date()}
              selectsStart
              minDate={new Date()}
              autoComplete={"off"}
              placeholderText={t("formLead.label_startDate")}
            />
          </div>
          <input 
            type="time" 
            id="startTime"
            name="startTime"
            ref={register(validations)}
            className={`form-control form-control-sm time ${
              errors["startTime"] ? "is-invalid" : ""
            }`}
            onChange={e => setHours(e.target.value, startDate, setStartDate)} 
            pattern="[0-9]{2}:[0-9]{2}"
            placeholder="hh:mm"
          />
          <input
            id={"startDate"}
            type={"hidden"}
            value={startDate}
            name="startDate"
            ref={register(validations)}
            onChange={onChange}
          />
          {(errors["startDate"] || errors["startTime"]) && (
            <ErrorDisplay
              errorMessage={ 
                errors["startDate"] 
                  ? errors["startDate"].message
                  : errors["startTime"].message 
              }
              isAllCols={true}
            />
          )}
        </div>
        <div className="p-2">to</div>
        <div>
          <div className="date mb-sm-2">
            <ReactDatePicker
              selected={endDate}
              locale={locale}
              className={`form-control ${small ? "form-control-sm" : ""} ${
                errors["endDate"] ? "is-invalid" : ""
              } `}
              name="endDatePicker"
              onChange={(date, e) => onChangevalue(date, e, true)}
              startDate={startDate}
              endDate={endDate}
              dateFormat="MM/dd/yyyy"
              openToDate={startDate}
              minDate={startDate}
              selectsEnd
              autoComplete={"off"}
              placeholderText={t("formLead.label_endDate")}
            />
          </div>
          <input 
            type="time" 
            id="endTime"
            name="endTime"
            ref={register(validations)}
            className={`form-control form-control-sm time ${
              errors["endTime"] ? "is-invalid" : ""
            }`}
            onChange={e => setHours(e.target.value, endDate, setEndDate)} 
            pattern="[0-9]{2}:[0-9]{2}"
            placeholder="hh:mm"
          />
          <input
            id={"endDate"}
            type={"hidden"}
            value={endDate}
            name="endDate"
            ref={register({
              validate: (endDate) =>
                !endDate
                  ? validations.required
                  : date1IsEqualsOrGreaterThanDate2(endDate, startDate) ||
                    "The end date must be greater than the start date",
            })}
            onChange={onChange}
          />
          {(errors["endDate"] || errors["endTime"]) && (
            <ErrorDisplay
              errorMessage={ 
                errors["endDate"] 
                  ? errors["endDate"].message
                  : errors["endTime"].message 
              }
              isAllCols={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default DateTimeRange;

/**
 * Sets hours of a date object.
 * @param {string} hours time value in format hh:mm
 * @param {Date} date Current date
 * @param {function} dateSetter Date setter callback
 */
const setHours = (hours, date, dateSetter) => {
  if (!date) return;
  hours = hours ?? '00:00'
  
  const [h, m] = hours.split(':');
  if (h && m) {
    const newDate = new Date(date); 
    newDate.setHours(parseInt(h), parseInt(m));
    dateSetter(newDate);
  }
}