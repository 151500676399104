import './formLead.scss'

import React, { useEffect, useState, useCallback, useContext } from "react";
import Auth from "../auth/auth";
import DynamicForm from "../dynamicForm/dynamicForm";
import json from "../../services/json/sampleFormData.json";
import { addLocation, updateLocation } from "../../services/restLocationService";
import { useDoSearch2 } from "../../store/doSearch2.state";

import {
  GetCountryList,
  GetCountryStatesList,
  getSearchLocation
} from "../../services/restSearchService";
import { COUNTRIES_LIST_IDS, LOCATION_STATUS } from "../../utils/constants";
import { CONGIF_PRODUCTION, CONFIG_STAGING } from "../../utils/configConstants";
import { dateToStringFormat } from "../../utils/utils";
import { useTranslation, Trans } from "react-i18next";
import { getExtraDataByLanguage } from "../../i18n";
import BullseyeLoader from "../bullseyeLoader";
import { getPostalCodePattern } from "../../utils/postalCodes";
import { isEmpty } from "lodash";
import LandingPageContent from "../landingPageContent/landingPageContent";
import { BullseyeContext } from "../../app2";
import { Button } from "react-bootstrap";
import classNames from "classnames";
const FormLead = ({
  title,
  subTitle = "",
  extraTitle = "",
  small = true,
  compact = true,
  GoogleCaptchaKey,
  ClientId,
  ApiKey
}) => {
  const { landingPageConfiguration, locatorConfiguration } = useContext(BullseyeContext);

  const [userData, setUserData] = useState({
    email: undefined,
    password: undefined,
    name: undefined,
    userId: undefined
  });

  const configForm =
    process.env.REACT_APP_ENVIROMENT !== "production"
      ? { ...CONFIG_STAGING }
      : { ...CONGIF_PRODUCTION };
  
  const [statusForm, setStatusForm] = useState({
    submited: false,
    hasErrors: false,
    errorMessage: "",
    validMessage: "",
    isLoading: true,
    isPreviewing: false
  });

  /** get configuration for host branc location */
  const [searchParameters2] = useState({
    countryId: COUNTRIES_LIST_IDS.CANADA,
    latitude: null,
    longitude: null,
    radius: 1,
    state: null,
    city: null,
    StartIndex: 0,
    pageSize: 2000,
    maxResults: 2000,
    categoriesIDs: `${configForm.CATEGORY_LEGION_BRANCH_LOCATION},${configForm.CATEGORY_LEGION_COMMAND},${configForm.CATEGORY_LEGION_BRANCH_PO_BOX}`, // location category Legion Branch Location AND COMMAND
    configClientSearch: {
      apiKey: ApiKey,
      clientId: ClientId,
    },
  });
  const doSearch2State = useDoSearch2(
    searchParameters2,
    useCallback(() => {}, [])
  );

  /** configuration for the formLead */
  const [
    countriesAndStatesListForFormLead,
    setCountriesAndStatesListForFormLead,
  ] = useState({
    countries: [
      {
        value: "",
        name: "Select Country",
      },
    ],
    states: [],
    labels: [
      {
        countryId: COUNTRIES_LIST_IDS.USA,
        labelState: "State",
        visibleState: true,
        labelZipCode: "Zip Code",
      },
      {
        countryId: COUNTRIES_LIST_IDS.CANADA,
        labelState: "Province",
        visibleState: true,
        labelZipCode: "Postal Code",
      },
      {
        countryId: COUNTRIES_LIST_IDS.UK,
        labelState: "Country",
        visibleState: true,
        labelZipCode: "Postcode",
      },
      {
        countryId: 0,
        labelState: "",
        visibleState: false,
        labelZipCode: "Postal Code",
      },
    ],
  });

  /**
   * configuration for the formLead with the brachHostLocations
   */
  const [formLeadConfiguration, setFormLeadConfiguration] = useState(null);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const dataByLanguage = getExtraDataByLanguage();
    let formConfiguration = dataByLanguage?.formData ?? json;
      setStatusForm({ ...statusForm, isLoading: false });
      setFormLeadConfiguration(formConfiguration);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doSearch2State.locations, i18n.language]);

  // Remember location to allow updating it.
  const [location, setLocation] = useState({});

  /**
   * Form preview handler.
   * @param {Object} data Submitted form data.
   */
  const onPreviewFormLead = async (data) => {
    const dataToSubmit = prepareLocationData(data);
    await saveLocation(dataToSubmit, LOCATION_STATUS.notCompleted).then(
      locationId => {
        // Load location and remember it to allow updating.
        getSearchLocation(locationId).then(l => {
          setLocation(l);
          setStatusForm({ ...statusForm, isPreviewing: true });
        })
      }
    );
  }

  /**
   * Form submit handler.
   * @param {Object} data Submitted form data.
   */
  const onSubmitFormLead = (data) => {
    setStatusForm({ ...statusForm, isPreviewing: false, submited: true });
    const dataToSubmit = prepareLocationData(data);
    saveLocation(dataToSubmit, LOCATION_STATUS.pending);
  }

  /**
   * Submits the actual lead form by clicking it's button.
   */
  const submitForm = () => {
    document.forms[0].getElementsByClassName('action-submit')[0].click();
  }

  /**
   * Saves (creates or updates) a location at Bullseye server.
   * @param {Object} formData Submitted form data.
   * @returns {number} Location ID
   */
  const saveLocation = async (dataToSubmit, locationStatusID) => {
    // Apply submitted form data on top of the existing locaiton object.
    dataToSubmit.myLoc = { ...location, ...dataToSubmit.myLoc }
    
    // Unset state data to allow changing via StateAbbr.
    dataToSubmit.myLoc.StateId = null;
    dataToSubmit.myLoc.StateName = null;

    // Unset geocoding details to regeocode.
    dataToSubmit.myLoc.GeoCodeStatusId = null;
    dataToSubmit.myLoc.DateLastGeoCoded = null;
    dataToSubmit.myLoc.Latitude = null;
    dataToSubmit.myLoc.Longitude = null;

    dataToSubmit.myLoc.LocationStatusID = locationStatusID;
    dataToSubmit.myLoc.Id = location?.Id === undefined ? null: location.Id;
    
    const action = dataToSubmit.myLoc.Id ? 'update' : 'add';
    // Unset username when updating a location to prevent creation a new user.
    if (action === 'update') {
      dataToSubmit.myLoc.UserName = null;
      dataToSubmit.myLoc.Password = null;
    }

    const response = action === 'update' 
      ? await updateLocation(dataToSubmit)
      : await addLocation(dataToSubmit);

    let status = { ...statusForm };
    status.submited = true;
    const result = action === 'update' 
      ? response.UpdateLocationResult
      : response.AddLocationResult;

    if (result.locationId < 0) {
      status.errorMessage = result.errorMsg.includes(
        "DuplicateEmail"
      )
        ? t("formLead.errorMessageUserExists")
        : result.errorMsg;
      status.hasErrors = true;
    } else {
      status.errorMessage = "";
      status.hasErrors = false;
      status.validMessage = formLeadConfiguration.response ?? "Valid form";
    }
    status.isPreviewing = false;

    setStatusForm(status);
    return result?.locationId;
  };

  /**
   * Converts form data to a location data ready for submission to API.
   * @param {Object} data Location form data.
   * @returns {Object} Location data ready for submission to API.
   */
  const prepareLocationData = (data) => {
    const locationData = {
        Id: null,
        InternetLocation: false,
        Name: data.locationName,
        Active: false,
        LocationStatusID: 2,
        Services: [{ ServiceID: 2, ServiceName: "Store Locator" }],
        URL: data.website,
        CountryId: data.CountryId,
        Address1: data.Address1,
        Address2: data.Address2,
        City: data.City,
        StateAbbr: data.StateAbbr,
        PostCode: data.PostalCode,
        ContactName: data.contact_name ?? "",
        EmailAddress: data.contact_email ?? "",
        Categories: [
          { CategoryId: configForm.CATEGORY_LEGION_REMEMBRANCE_DAY_LOCATION },
        ], /// categoryId for Legion Remembrance Day Location
        Attributes: [
          {
            AttributeId: configForm.ATTRIBUTES.SUBMITTER_NAME,
            AttributeName: "Submitter Name",
            TextValue: userData.name,
          },
          {
            AttributeId: configForm.ATTRIBUTES.SUBMITTER_EMAIL,
            AttributeName: "Submitter Email",
            TextValue: userData.email,
          },
          {
            AttributeId: configForm.ATTRIBUTES.CELEBRATION_TITLE,
            AttributeName: "Celebration Title",
            TextValue: data.envent_title,
          },
          {
            AttributeId: configForm.ATTRIBUTES.CELEBRATION_START_DATE,
            AttributeName: "Celebration Start Date",
            TextValue: dateToStringFormat(data.startDate, "MM/DD/yyyy"),
          },
          {
            AttributeId: configForm.ATTRIBUTES.CELEBRATION_END_DATE,
            AttributeName: "Celebration End Date",
            TextValue: data.endDate
              ? dateToStringFormat(data.endDate, "MM/DD/yyyy")
              : "",
          },
          {
            AttributeId: configForm.ATTRIBUTES.CELEBRATION_START_DATE_TIME,
            AttributeName: "Celebration Start Date Time Range",
            TextValue: dateToStringFormat(data.startDate, "h:mm a"),
          },
          {
            AttributeId: configForm.ATTRIBUTES.CELEBRATION_END_DATE_TIME,
            AttributeName: "Celebration End Date Time Range",
            TextValue: data.endDate
              ? dateToStringFormat(data.endDate, "h:mm a")
              : "",
          },
          {
            AttributeId: configForm.ATTRIBUTES.CELEBRATION_DESCRIPTION,
            AttributeName: "Celebration Description",
            MemoValue: data.description ?? "",
          },
          {
            AttributeId: configForm.ATTRIBUTES.HOST_LEGION_BRANCH,
            AttributeName: "Host Legion Branch",
            NumericValue: data.host_legion_branch,
          },
        ],
        MemberId: userData.userId,
        FirstName: userData.name,
        UserName: userData.email,
        Password: userData.password,
    }
    
    return {
      ClientId: ClientId,
      ApiKey: ApiKey,
      myLoc: locationData,
    };
  }

  useEffect(() => {
    const setLeadForm = async () => {
      let countriesconfig = {
        ...countriesAndStatesListForFormLead,
      };
      if (countriesAndStatesListForFormLead.states.length < 1) {
        const countries = await GetCountryList(
          searchParameters2.configClientSearch
        );
        /** get list of states for each Contry*/
        let states = [];
        /**get USA STATES */
        if (countries.find((el) => el.Id === COUNTRIES_LIST_IDS.USA)) {
          const statesUS = await GetCountryStatesList(
            COUNTRIES_LIST_IDS.USA,
            searchParameters2.configClientSearch
          );
          states.push({
            countryId: COUNTRIES_LIST_IDS.USA,
            list: statesUS.map((state) => {
              return {
                id: state.Abbr,
                value: state.Abbr,
                name: state.Abbr + " - " + state.Name,
              };
            }),
          });
        }
        /** get CANADA state */
        if (
          countries.find(
            (el) => el.Id === COUNTRIES_LIST_IDS.CANADA,
            searchParameters2.configClientSearch
          )
        ) {
          const statesUS = await GetCountryStatesList(
            COUNTRIES_LIST_IDS.CANADA,
            searchParameters2.configClientSearch
          );
          states.push({
            countryId: COUNTRIES_LIST_IDS.CANADA,
            list: statesUS.map((state) => {
              return {
                id: state.Abbr,
                value: state.Abbr,
                name: state.Abbr + " - " + state.Name,
              };
            }),
          });
        }
        /** Get UK STATES */
        if (countries.find((el) => el.Id === COUNTRIES_LIST_IDS.UK)) {
          const statesUS = await GetCountryStatesList(
            COUNTRIES_LIST_IDS.UK,
            searchParameters2.configClientSearch
          );
          states.push({
            countryId: COUNTRIES_LIST_IDS.UK,
            list: statesUS.map((state) => {
              return {
                id: state.Abbr,
                value: state.Abbr,
                name: state.Abbr + " - " + state.Name,
              };
            }),
          });
        }

        countriesconfig = {
          ...countriesAndStatesListForFormLead,
          countries: countries.map((country) => {
            return { 
              value: country.Id, 
              name: country.Name, 
              postalCodePattern: getPostalCodePattern(country.Code)
            };
          }),
          states: states,
        };
        setCountriesAndStatesListForFormLead(countriesconfig);
      }
      // const form = json; //await getLeadFormConfiguration(formId);
      // setFormLeadConfiguration(form);
    };
    setLeadForm();
  }, [countriesAndStatesListForFormLead, searchParameters2.configClientSearch]);

  const isNewUser = 
    userData.userId === undefined &&
    userData.email !== undefined &&
    userData.name !== undefined &&
    userData.password !== undefined;

    // Toggle "previewing" class to the root element
    // to allow changing styles.
    useEffect(() => {
      const rootEl = document.getElementById('root');
      rootEl.classList.toggle('previewing', statusForm.isPreviewing);
    }, [statusForm])

    const isAuthenticated = (userData.userId !== undefined || isNewUser);

  return (
    <div className='form-lead'>
      { isAuthenticated 
        ? ( statusForm.isLoading && <BullseyeLoader /> )
        : <Auth 
            onAuthenticated={(data) => {setUserData(data)}} 
            onNewUser={(data) => {setUserData(data)}} 
          />
      }
      {formLeadConfiguration &&
        countriesAndStatesListForFormLead.states.length > 0 && (
          <>
            {!isEmpty(location) && statusForm.isPreviewing &&
              <>
                <div className="bullseye-red h4">{ t("formLead.previewTitle") }</div>
                <div className="form-preview-actions alert alert-warning p-3 m-0 text-center">
                  <Button className="px-4 mr-3" variant="danger" onClick={submitForm}>
                    { t("formLead.button_submit") }
                  </Button>
                  {t("formLead.or")} 
                  <Button className="px-4 ml-3" variant="outline-danger"
                    onClick={() => {setStatusForm({ ...statusForm, isPreviewing: false })}}
                  >
                    { t("formLead.button_edit") }
                  </Button>
                </div>
                <div className="landing-page-preview">
                  <LandingPageContent
                    landingPageConfiguration={landingPageConfiguration}
                    locatorConfiguration={locatorConfiguration}
                    locationId={location.Id}
                  />
                </div>
              </>
            }

            <div className={classNames({hidden: statusForm.isPreviewing})}>
              {statusForm.submited === true && <div className="h4 bullseye-red">
                { t('formLead.success') }
              </div>}
              {isAuthenticated && statusForm.isLoading && <BullseyeLoader />}
              {isAuthenticated && <>
                <DynamicForm
                  formLeadConfiguration={formLeadConfiguration}
                  title={title}
                  subTitle={subTitle}
                  extraTitle={extraTitle}
                  disclamer={t("formLead.disclamer")}
                  small={small}
                  compact={compact}
                  onSubmit={onSubmitFormLead} 
                  onPreview={onPreviewFormLead}
                  GoogleCaptchaKey={GoogleCaptchaKey}
                  countriesAndStatesConfig={countriesAndStatesListForFormLead}
                  extraMessageValidation={statusForm}
                  allowPreview
                  allowSubmit={location?.Id !== undefined}
                />
                {statusForm.submited !== true && <div className="alert alert-warning mt-4 mb-0" role="alert">
                  <Trans i18nKey="formLead.disclaimerRequestForm">
                    I understand that the personal information collected for the
                    Remembrance Day Ceremony locator will be used by The Royal
                    Canadian Legion (“Legion”) for the purposes of processing my
                    submission and communicating with me about my submission and
                    may be used internally by the Legion at the national level
                    for other purposes provided in its Privacy Statement at
                    <a
                      href={
                        i18n.language.includes("en")
                          ? "https://legion.ca/legal"
                          : "https://legion.ca/fr/aspect-juridiques"
                      }
                    >
                      legion.ca/legal
                    </a>
                    . By completing and submitting this form, I am giving my
                    consent for the Legion to collect, use or disclose my
                    personal information for these purposes. I understand that I
                    may withdraw my consent at any time by contacting the Legion
                    at 855-330-3344.
                  </Trans>
                </div>}
              </>}
            </div>
          </>
        )}
    </div>
  );
};
export default FormLead;
