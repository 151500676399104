import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const BullseyeLoader = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Loader
        type="ThreeDots"
        color="#990000"
        height={100}
        width={100}
        timeout={1500} //2 secs
      />
    </div>
  );
};

export default BullseyeLoader;
