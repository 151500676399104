import http from "./httpService";

const endpointPath = "RestLocation.svc";

export async function addLocation(dataPost) {
  http.setSecurityParameters2(dataPost.ClientId, dataPost.ApiKey);
  const location = await http.post(
    `${endpointPath}/AddLocation`, 
    dataPost
  );
  return location.data;
}

export async function updateLocation(dataPost) {
  http.setSecurityParameters2(dataPost.ClientId, dataPost.ApiKey);
  const location = await http.post(
    `${endpointPath}/UpdateLocation`, 
    dataPost
  );
  return location.data;
}
