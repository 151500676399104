import React, { useState } from "react";
import Label from "../label/label";
import Select from "react-select";
import ErrorDisplay from "../errorDisplay/errorDisplay";
import "./autocompleteInput.scss";
const AutocompleteInput = ({
  name,
  label,
  small = false,
  compact = false,
  register,
  errors,
  placeholder = "",
  required = false,
  onChange,
  options,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [inputSelectedOption, setInputSelectedOption] = useState("");
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setInputSelectedOption(selectedOption.value);
  };

  return (
    <div className="form-group row">
      {!compact && <Label htmlFor={name} label={label} required={required} />}
      <div
        className={`react-select py-0 col-sm-8 ${errors[name] ? "is-invalid" : ""} `}
      >
        <Select
          name={name}
          value={selectedOption}
          register={register}
          onChange={handleChange}
          options={options}
          placeholder={!compact ? placeholder : label}
          // theme={(theme) => ({
          //   ...theme,
          //   borderRadius: 0,
          //   colors: {
          //     ...theme.colors,
          //     primary25: "#0784e9",
          //     primary: "#0784e9",
          //   },
          // })}
          {...props}
        />
        <input
          id={name}
          type={"hidden"}
          value={inputSelectedOption}
          name={name}
          ref={register}
          onChange={onChange}
        />
      </div>
      {errors[name] && !inputSelectedOption && (
        <ErrorDisplay errorMessage={errors[name]?.message} />
      )}
    </div>
  );
};
export default AutocompleteInput;
