import React, { useEffect, useState, useCallback, useContext } from "react";
import { useDoSearch2 } from "../../store/doSearch2.state";
import { saveReview } from "../../services/restBullseyeReviews";
import CardWithImage from "../cardWithImage";
import LocationInformation from "../locationInformation";
import SimpleListWithTitle from "../simpleListWithTitle";
import Slider from "../slider";
import SocialMediaIcons from "../socialMediaIcons";
import VideoPlayer from "../videoPlayer";
import Testimonials from "../testimonials";
import GoogleReviews from "../googleReviews";
import BullseyeReviews from "../bullseyeReviews";
import GoogleMap from "../googleMap";
import globalConfigProp from "../../config.json";
import BusinessHours from "../businessHours";
import PhotoListWithTitle from "../photoListWithTitle";
import NearbyLocations from "../nearbyLocations";
import Showcase from "../showcase";
import MultiCarousel from "../multiCarousel";
import GalleryImages from "../galleryImages";

import {
  changeLanguageDates,
  documentIcon,
  FixRelativePath,
  NewId,
} from "../../utils/utils";
import {
  COUNTRIES_LIST_IDS,
  MAP_TYPES,
  REVIEW_TYPES,
} from "../../utils/constants";
import { CONGIF_PRODUCTION, CONFIG_STAGING } from "../../utils/configConstants";

import CardWrapper from "../cardWrapper";
import Events from "../events";

import FormLead from "../formLead";
import { useLocation } from "../../store/location.state";

import { getSearchLocation2 } from "../../services/restSearchService";
import AboutLegion from "../aboutLegion/aboutLegion";
import { useTranslation } from "react-i18next";
import MapboxGL from "../mapboxGL/mapboxGL";
import { BullseyeContext } from "../../app2";
import { Helmet } from "react-helmet-async";

const LandingPageContent = (props) => {
  const { distanceQS, locationId } = props;
  const { landingPageConfiguration, locatorConfiguration } =
    useContext(BullseyeContext);

  /* Location data */
  const [locationState, getAttributeValue] = useLocation(
    locationId,
    null,
    landingPageConfiguration.landingPageId,
    locatorConfiguration.clientId,
    locatorConfiguration.apiKey
  );
  const locationData = locationState.location;

  const [locationHostLegionBranchState, setLocationHostLegionBranchState] =
    useState(null);

  const [mainHeaderContainer, setMainHeaderContainer] = useState([]);
  const [mainBodyContainer, setMainBodyContainer] = useState([]);
  const [sideMenuContainer, setSideMenuContainer] = useState([]);
  const [googleMapIsReady] = useState(false);

  /** configuration por nearbyLocation */
  const [searchParameters, setSearchParameters] = useState({
    countryId: null,
    latitude: null,
    longitude: null,
    state: null,
    city: null,
    pageSize: 5,
    configClientSearch: {
      apiKey: locatorConfiguration.apiKey,
      clientId: locatorConfiguration.clientId,
    },
  });

  const getBlockByType = (blockType) => {
    const block = landingPageConfiguration.blocks.find(
      (x) => x.blockType === blockType
    );

    return block;
  };
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const getBrachLocation = async () => {
      const attBranch = getAttributeValue(null, false, "Host Legion Branch");
      if (attBranch) {
        const locationBranch = await getSearchLocation2(
          attBranch,
          null,
          landingPageConfiguration.landingPageId,
          locatorConfiguration.clientId,
          locatorConfiguration.apiKey
        );
        setLocationHostLegionBranchState(locationBranch);
      }
    };
    if (locationState?.location?.Attributes?.length > 0) {
      getBrachLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState, landingPageConfiguration]);
  //get properties from env
  const configForm =
    process.env.REACT_APP_ENVIROMENT !== "production"
      ? { ...CONFIG_STAGING }
      : { ...CONGIF_PRODUCTION };
  // Look for neaby locations
  useEffect(() => {
    if (locationData.Latitude && locationData.Longitude) {
      setSearchParameters({
        ...searchParameters,
        countryId: COUNTRIES_LIST_IDS.CANADA,
        state: null,
        city: null,
        latitude: locationData.Latitude,
        longitude: locationData.Longitude,
        radius: 5000,
        pageSize: 4,
        categoriesIDs: configForm.CATEGORY_LEGION_REMEMBRANCE_DAY_LOCATION, // location category Legion Branch Location
        configClientSearch: {
          apiKey: locatorConfiguration.apiKey,
          clientId: locatorConfiguration.clientId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData, locatorConfiguration]);

  const doSearch2State = useDoSearch2(
    searchParameters,
    useCallback(() => {}, [])
  );

  let locationsState = doSearch2State.locations.slice(1, 4);

  useEffect(() => {
    if (locationState.loading) return;

    const mainBodyContainer = [];
    const mainHeaderContainer = [];
    const sideMenuContainer = [];

    const socialMediaIcons = [];
    const twoColumnComponents = [];

    const websiteBlock = getBlockByType(7); // Website
    const phoneBlock = getBlockByType(8); // Phone
    const contactNameBlock = getBlockByType(30); // Contact Name
    const contactPositionBlock = getBlockByType(31); // Contact Position
    const bannerImageBlock = getBlockByType(26); // Banner image
    const logoImageBlock = getBlockByType(27); // Logo image
    let bannerImage;
    let logoImage;

    if (bannerImageBlock) {
      // eslint-disable-next-line no-unused-vars
      bannerImage = getAttributeValue(bannerImageBlock.referenceId, true);
    }

    if (logoImageBlock) {
      logoImage = getAttributeValue(logoImageBlock.referenceId, true);
    }

    const eventName = getAttributeValue(
      configForm.CELEBRATION_TITLE,
      false,
      "Ceremony Title"
    );
    mainHeaderContainer.push(
      <CardWrapper paddingClass="p-0 mt-0" key={1}>
        <Slider
          title={eventName}
          subTitle={locationData.Name}
          logo={logoImage}
          backgroundImage={"/images/banners/banner.jpg"}
          directions={""}
          website={websiteBlock && locationData.URL}
        ></Slider>
      </CardWrapper>
    );

    landingPageConfiguration.blocks.sort((x, y) => x.order - y.order);

    landingPageConfiguration.blocks.forEach((block) => {
      const container =
        block.position === "MainBodyContainer"
          ? mainBodyContainer
          : sideMenuContainer;

      switch (block.blockType) {
        case 2: // Location Name, 1 - Map,
          const mapCoordenates = [
            {
              Id: locationData.Id,
              Name: locationData.Name,
              Latitude: locationData.Latitude,
              City: locationData.City,
              State: locationData.StateAbbreviation,
              PostCode: locationData.PostCode,
              Longitude: locationData.Longitude,
              CountryName: locationData.CountryCode,
              Address1: locationData.Address1,
              iconSize: [50, 50],
            },
          ];
          const mapCoordsMapBox = {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: mapCoordenates[0],
                geometry: {
                  type: "Point",
                  coordinates: [locationData.Longitude, locationData.Latitude],
                },
              },
            ],
          };

          const storeHoursBlock = getBlockByType(10); // Store Hours

          let showTwoColumns = block.customSetting === "col1";

          if (!storeHoursBlock || locationData.DailyHoursList.length === 0) {
            showTwoColumns = false;
          }

          const mapBlock = getBlockByType(1); // map
          const locationInformationBlock = (
            <CardWrapper
              key={1}
              paddingClass={
                block.position === "MainBodyContainer"
                  ? "p-3 mr-0 mr-lg-2 flex-grow-1"
                  : ""
              }
            >
              <div
                className={
                  block.position !== "MainBodyContainer" || showTwoColumns
                    ? showTwoColumns
                      ? ""
                      : "p-3"
                    : "d-flex d-flex justify-content-between flex-column flex-md-row"
                }
              >
                {mapBlock && (
                  <div
                    className={
                      "mb-2 w-100" +
                      (block.position === "MainBodyContainer"
                        ? showTwoColumns
                          ? " pr-0"
                          : " pr-0 pr-md-4"
                        : "")
                    }
                    style={{ height: 320 }}
                  >
                    {/* {locatorConfiguration?.mappingSettings[0]
                      .geocodingServiceID === MAP_TYPES.mapBox &&
                      locatorConfiguration.mappingSettings[0]?.token && (
                        <MapboxGL
                          features={mapCoordsMapBox?.features}
                          mapboxToken={
                            locatorConfiguration.mappingSettings[0]?.token
                          }
                          showLandingPageLink={false}
                          customPin={
                            locatorConfiguration?.interfaceIcons[0]
                              ? locatorConfiguration?.interfaceIcons[0]
                                  .customMapIcon
                              : ""
                          }
                        />
                      )} */}

                    {locatorConfiguration.showGoogleMap &&
                      locatorConfiguration.mappingSettings[0]
                        .geocodingServiceID === MAP_TYPES.be_google &&
                      locatorConfiguration.mappingSettings[0]?.apiKey && (
                        <GoogleMap
                          markers={mapCoordenates}
                          googleApikey={
                            locatorConfiguration.mappingSettings[0]?.apiKey
                          }
                          showLandingPageLink={false}
                          // onMapReady={() => //setGoogleMapIsReady(true)
                          // }
                          customPin={
                            locatorConfiguration?.interfaceIcons[0]
                              ? locatorConfiguration?.interfaceIcons[0]
                                  .customMapIcon
                              : ""
                          }
                        />
                      )}
                  </div>
                )}
                <div>
                  <LocationInformation
                    locationId={locationData.locationId}
                    address1={locationData.Address1}
                    city={locationData.City}
                    stateAbbr={locationData.StateAbbreviation}
                    postalCode={locationData.PostCode}
                    country={locationData.CountryCode}
                    locationName={locationData.Name}
                    phone={phoneBlock && locationData.PhoneNumber}
                    contactName={contactNameBlock && locationData.ContactName}
                    contactPosition={
                      contactPositionBlock && locationData.ContactPosition
                    }
                    email={locationData.EmailAddress}
                    websiteUrl={locationData.URL}
                    distance={distanceQS}
                  />
                </div>
              </div>
            </CardWrapper>
          );
          if (showTwoColumns) {
            twoColumnComponents.push(locationInformationBlock);
          } else {
            container.push(locationInformationBlock);
          }
          break;
        case 10: // Store Hours
          // todo implement David code to fix the open now and close now right nos is harcdoing
          if (locationData.DailyHoursList.length > 0) {
            const isClose = locationData.DailyHoursList[0].NoHoursAvailable;
            const businessHours = (
              <CardWrapper
                key={10}
                paddingClass={
                  block.customSetting === "col2" ? "p-3 flex-grow-1" : "p-3"
                }
              >
                <BusinessHours
                  daysArray={locationData.DailyHoursList.map((hour) => {
                    return {
                      day: hour.NameOfDay,
                      hours: hour.NoHoursAvailable
                        ? "Close"
                        : hour.HoursDisplayText,
                    };
                  })}
                  isClose={isClose}
                  title={block.title}
                />
              </CardWrapper>
            );

            if (block.customSetting === "col2") {
              twoColumnComponents.push(businessHours);

              container.push(
                <div className="d-flex flex-wrap">
                  {twoColumnComponents.map((component) => {
                    return component;
                  })}
                </div>
              );
            } else {
              container.push(businessHours);
            }
          }
          break;
        case 11: // get the lead form Configuration
          container.push(
            <CardWrapper key={block.referenceId}>
              <FormLead
                formId={block.referenceId}
                locationId={locationData.Id}
                title={block.title}
                GoogleCaptchaKey={globalConfigProp.GoogleCaptchaKey}
                ClientId={landingPageConfiguration.clientId}
                ApiKey={landingPageConfiguration.apiKey}
              />
            </CardWrapper>
          );
          break;
        case 12: // CustomHTML
          if (block.referenceId) {
            container.push(
              <CardWrapper key={12}>
                <AboutLegion />
              </CardWrapper>
            );
          } else if (block.customHtml) {
            container.push(
              <CardWrapper key={12}>
                <div dangerouslySetInnerHTML={{ __html: block.customHtml }} />
              </CardWrapper>
            );
          }
          break;
        case 13: // Category Group
          const categoryTree = locationData.CategoryTree.filter(
            (cat) => cat.ID === block.referenceId
          );

          if (
            categoryTree.length > 0 &&
            categoryTree[0].SubCategories.length > 0
          ) {
            container.push(
              <CardWrapper key={block.referenceId}>
                <SimpleListWithTitle
                  title={block.title}
                  items={categoryTree[0].SubCategories.map((cat) => {
                    return { id: cat.ID, title: cat.Name };
                  })}
                  numberOfColumns={1}
                />
              </CardWrapper>
            );
          }

          break;
        case 14: // events
          const eventsBlock = getBlockByType(14);
          // get the attributes values for this celebration.
          const eventStartDate = getAttributeValue(
            configForm.ATTRIBUTES.CELEBRATION_START_DATE,
            false,
            "Ceremony Start Date"
          );
          const eventStartHour = getAttributeValue(
            configForm.ATTRIBUTES.CELEBRATION_START_DATE_TIME,
            false,
            "Ceremony Start Date Time Range"
          );
          const eventEndDate = getAttributeValue(
            configForm.ATTRIBUTES.CELEBRATION_END_DATE,
            false,
            "Ceremony End Date"
          );
          const eventEndtHour = getAttributeValue(
            configForm.ATTRIBUTES.CELEBRATION_END_DATE_TIME,
            false,
            "Ceremony End Date Time Range"
          );

          if (locationData.Events && eventsBlock && eventStartDate) {
            const eventList = [
              {
                ClientId: 1,
                StartDate: eventStartDate + " " + eventStartHour,
                EndDate: eventEndDate ? eventEndDate + " " + eventEndtHour : "",
                EventId: 1,
                EventTitle: "",
                EventDescription: "",
              },
            ];
            container.push(
              <CardWrapper key={14}>
                <Events
                  title={eventsBlock.title}
                  eventList={eventList}
                  showDateLikeDescription={true}
                />
              </CardWrapper>
            );
          }
          break;
        case 15: // Complex Attributes
          // not in use
          break;
        case 16: // Benefits
          if (landingPageConfiguration.benefits?.length > 0) {
            container.push(
              <CardWrapper key={16}>
                <Showcase
                  title="Benefits"
                  items={landingPageConfiguration.benefits}
                />
              </CardWrapper>
            );
          }
          break;
        case 17: // Gallery photos / Landing Page Photos
          // TODO: We need to support alternative text and not hardcoding
          if (
            block.customSetting === "carousel" &&
            locationData.LandingPageImages.length > 0
          ) {
            container.push(
              <CardWrapper key={17}>
                <MultiCarousel
                  photos={locationData.LandingPageImages.map((photo) => {
                    return {
                      imageURL: photo,
                      id: NewId(),
                      alternativeText: "Bullseye Photo",
                    };
                  })}
                  title={block.title}
                  itemNumber={1}
                  containerExtraClass="m-2"
                />
              </CardWrapper>
            );
          }
          if (
            block.customSetting === "masonry" &&
            locationData.LandingPageImages.length > 0
          ) {
            container.push(
              <CardWrapper key={171}>
                <GalleryImages
                  title="Gallery"
                  photos={locationData.LandingPageImages.map((photo) => {
                    return {
                      imageURL: photo,
                      id: NewId(),
                      alternativeText: "some photo",
                    };
                  })}
                  columns={4}
                  direction="column"
                />
              </CardWrapper>
            );
          }
          break;
        case 18: // Promotions
          if (locationData.Coupons.length > 0) {
            container.push(
              <CardWrapper key={18}>
                <PhotoListWithTitle
                  title={block.title}
                  items={locationData.Coupons.map((coupon) => {
                    return {
                      id: coupon.CouponId,
                      image: coupon.ThumbnailImage,
                      body: coupon.CouponText,
                      url: coupon.PromoPageURL,
                    };
                  })}
                  maxItemsToDisplay={3}
                />
              </CardWrapper>
            );
          }
          break;
        case 19: // Reviews
          if (
            landingPageConfiguration.reviewConfiguration.reviewType ===
              REVIEW_TYPES.google &&
            locationData.GooglePlaceID
          ) {
            // TODO: we need to validate if the api for google is loaded see how in ecoatm
            container.push((googleMapIsReady) => (
              <CardWrapper key={19}>
                <GoogleReviews
                  googleScriptsReady={googleMapIsReady}
                  title={block.title}
                  GoogleplaceId={locationData.GooglePlaceID}
                />
              </CardWrapper>
            ));
          }

          if (
            landingPageConfiguration.reviewConfiguration.reviewType ===
              REVIEW_TYPES.bullseye &&
            locationData.BullseyeReviews
          ) {
            const onSubmitBullseyeReviews = (data) => {
              data.LocationId = locationData.Id;
              const dataForSubmit = {
                ClientId: landingPageConfiguration.clientId,
                ApiKey: landingPageConfiguration.apiKey,
                NewReview: data,
              };
              saveReview(dataForSubmit);
            };

            container.push(
              <CardWrapper key={191}>
                <BullseyeReviews
                  title={block.title}
                  reviews={locationData.BullseyeReviews}
                  onSubmit={onSubmitBullseyeReviews}
                  reviewConfiguration={
                    landingPageConfiguration.reviewConfiguration
                  }
                />
              </CardWrapper>
            );
          }

          break;
        case 20: // Facebook
          const facebook = locationData.SocialMedia.find(
            (x) => x.name === "facebook"
          );

          if (facebook) {
            socialMediaIcons.push(facebook);
          }
          break;
        case 21: // Twitter
          const twitter = locationData.SocialMedia.find(
            (x) => x.name === "twitter"
          );
          if (twitter) {
            socialMediaIcons.push(twitter);
          }

          break;
        case 22: // LinkedIn
          const linkedin = locationData.SocialMedia.find(
            (x) => x.name === "linkedin"
          );
          if (linkedin) {
            socialMediaIcons.push(linkedin);
          }

          break;
        case 23: // Instagram
          const instagram = locationData.SocialMedia.find(
            (x) => x.name === "instagram"
          );
          if (instagram) {
            socialMediaIcons.push(instagram);
          }

          break;
        case 24: // Pinterest
          const pinterest = locationData.SocialMedia.find(
            (x) => x.name === "pinterest"
          );
          if (pinterest) {
            socialMediaIcons.push(pinterest);
          }
          break;
        case 25: // Yelp
          const yelp = locationData.SocialMedia.find((x) => x.name === "yelp");
          if (yelp) {
            socialMediaIcons.push(yelp);
          }

          break;
        case 28: // ShowcaseContent
          // not in use
          break;
        case 32: // About
          let aboutAttribute = getAttributeValue(
            configForm.CELEBRATION_DESCRIPTION,
            false,
            "Ceremony Description"
          );

          const profileImage = getBlockByType(9); // Logo image

          if (aboutAttribute) {
            container.push(
              <CardWrapper key={32}>
                <CardWithImage
                  body={aboutAttribute}
                  title={`${t("landingPage.label_about")}`}
                  image={
                    profileImage && locationData.ImageFileUrl
                      ? FixRelativePath(locationData.ImageFileUrl)
                      : null
                  }
                />
              </CardWrapper>
            );
          }
          break;
        case 33: // Social Media
          if (socialMediaIcons.length > 0) {
            container.push(
              <CardWrapper key={33}>
                <SocialMediaIcons icons={socialMediaIcons} />
              </CardWrapper>
            );
          }
          break;
        case 34: // documents
          const documents = locationData.Attributes.filter((attr) =>
            block.customSetting.includes(attr.AttributeId)
          );

          if (documents.length > 0) {
            container.push(
              <CardWrapper key={34}>
                <SimpleListWithTitle
                  title="Documents"
                  items={documents.map((doc) => {
                    return {
                      id: doc.AttributeId,
                      title: doc.AttributeDisplayText,
                      url: doc.AttributeValue,
                    };
                  })}
                  icon={documentIcon}
                />
              </CardWrapper>
            );
          }
          break;
        case 35: // Testimonials
          const testimonials = [];

          const testimonialsComplexAttr = locationData.ComplexAttributes.filter(
            (attr) => attr.AttributeId === block.referenceId
          );

          testimonialsComplexAttr.forEach((testimonialInstance) => {
            // TODO: we can't do this, we need to find the correct attibute for description and author
            // for test we will assume right now the position [0] will be always the description
            testimonials.push({
              id: NewId(),
              description: testimonialInstance.SubAttributes[0].AttributeValue,
              author: testimonialInstance.SubAttributes[1].AttributeValue,
            });
          });
          if (testimonials.length > 0) {
            container.push(
              <CardWrapper key={35}>
                <Testimonials title={block.title} testimonials={testimonials} />
              </CardWrapper>
            );
          }
          break;
        case 36: // Videos
          const videos = [];

          const videosComplexAttr = locationData.ComplexAttributes.filter(
            (attr) => attr.AttributeId === block.referenceId
          );

          videosComplexAttr.forEach((videoInstance) => {
            // TODO: we can't do this, we need to find the correct attibute for description and author
            // for test we will assume right now the position [0] will be always the description
            videos.push({
              videoUrl: videoInstance.SubAttributes[0].AttributeValue,
              previewImage:
                videoInstance.SubAttributes[0].AttributeDisplayImage,
            });
          });
          if (videos.length > 0) {
            container.push(
              <CardWrapper key={36}>
                {videos.map((video, index) => (
                  <div key={NewId()} style={{ height: 400, marginBottom: 50 }}>
                    <VideoPlayer
                      title={index === 0 ? "Videos" : ""}
                      videoList={[video.videoUrl]}
                      light={video.previewImage ?? false}
                      autoPlaying={video.previewImage ? true : false}
                    />
                  </div>
                ))}
              </CardWrapper>
            );
          }
          break;
        case 37: // Nearby Locations
          const pinMapNearby =
            locatorConfiguration?.interfaceIcons?.length > 0
              ? locatorConfiguration?.interfaceIcons[0].customMapIcon
              : "/images/beIcon.png";
          container.push((nearbyLocations) => (
            <CardWrapper key={37}>
              <NearbyLocations
                title={t("landingPage.label_nearbyCelebrations")}
                nearbyLocationsList={nearbyLocations}
                customPin={pinMapNearby}
              />
            </CardWrapper>
          ));
          break;
        case 38: //
          container.push((locationHostLegionBranchState) => (
            <>
              <CardWrapper key={38} id="locationHostLegionBranchState">
                <LocationInformation
                  title={t("landingPage.label_hostLegionBranch")}
                  address1={locationHostLegionBranchState.Address1}
                  city={locationHostLegionBranchState.City}
                  stateAbbr={locationHostLegionBranchState.StateAbbreviation}
                  postalCode={locationHostLegionBranchState.PostCode}
                  country={locationHostLegionBranchState.CountryCode}
                  locationName={locationHostLegionBranchState.Name}
                  phone={locationHostLegionBranchState.PhoneNumber}
                  contactName={""}
                  contactPosition={""}
                  email={locationHostLegionBranchState.EmailAddress}
                  websiteUrl={locationHostLegionBranchState.URL}
                  showDirection={false}
                />
              </CardWrapper>
            </>
          ));
          break;
        default:
          break;
      }
    });
    changeLanguageDates(i18n.language);
    setMainHeaderContainer(mainHeaderContainer);
    setMainBodyContainer(mainBodyContainer);
    setSideMenuContainer(sideMenuContainer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData, locatorConfiguration, i18n.language]);

  const getComponentToMapDisplay = (el) => {
    if (typeof el === "function") {
      if (
        el.toString().includes("nearbyLocations") &&
        locationsState.length > 0
      )
        return el(locationsState);
      else if (
        el.toString().includes("locationHostLegionBranchState") &&
        locationHostLegionBranchState
      ) {
        return el(locationHostLegionBranchState);
      } else if (el.toString().includes("googleMapIsReady"))
        return el(googleMapIsReady);
    } else {
      return el;
    }
  };

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href={landingPageConfiguration.templateCssURLPath}
        />
      </Helmet>
      <div className="row">
        <div className="col-12 headerContainer__container">
          {mainHeaderContainer.map((el) => el)}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-5 col-lg-4 sideMenuContainer__container">
          {sideMenuContainer.map((el) => {
            return getComponentToMapDisplay(el);
          })}
        </div>
        <div className="col-12 col-md-7 col-lg-8 mainBodyContainer__container">
          {mainBodyContainer.map((el) => {
            return getComponentToMapDisplay(el);
          })}
        </div>
      </div>
    </>
  );
};

export default LandingPageContent;
